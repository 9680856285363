import React from "react";
import "./footer.css";
import logo from "../../assets/atlasIcon.svg";
import name from "../../assets/atlasName.svg";
const Footer = (): JSX.Element => {
  return (
    <nav className="footer">
      <div className="footer-text">Open Source by</div>
      <div className="logo-container">
        <img className="atlas-logo atlas-icon" src={logo} />
        <img className="atlas-logo atlas-name" src={name} />
      </div>
    </nav>
  );
};

export default Footer;
