import { Image } from "@davatar/react";
import { useContext, useEffect, useState } from "react";
import { EthereumContext } from "../contexts/ethereum";
import "./transfer.css";
import { BigNumber, ethers } from "ethers";
import { toast } from "react-toastify";

interface IToken {
  image: string;
  tokenNumber: number;
}

interface IErr {
  contract: boolean;
  transferAddress: boolean;
}

const Transfer = () => {
  const {
    setContract,
    tokensInWallet,
    baseURI,
    node,
    contractSigner,
    account,
  } = useContext(EthereumContext);
  const [currentToken, setCurrentToken] = useState<BigNumber>();
  const [tokenData, setTokenData] = useState<IToken[]>([]);
  const [transferAddress, setTransferAddress] = useState<string>("");
  const [err, setErr] = useState<IErr>({
    contract: false,
    transferAddress: false,
  });

  const fetchData = async () => {
    try {
      const _tokenData: IToken[] = [];
      if (baseURI) {
        for (let i = 0; i < tokensInWallet!.length; i++) {
          const tokenNumber = tokensInWallet![i].toNumber();
          console.log(baseURI);
          let uri = baseURI + tokensInWallet![i].toString();
          if (uri.slice(0, 7) == "ipfs://") {
            uri = uri.replace("ipfs://", "https://dweb.link/ipfs/");
          }
          console.log("err", i, uri);
          const data = await fetch(uri)
            .then((result) => result.json())
            .then((data) => {
              return { image: data.image, tokenNumber: tokenNumber };
            });

          _tokenData.push(data);
        }
        console.log(_tokenData);
      }
      setTokenData(_tokenData);
    } catch (err: any) {
      console.log(err);
      toast.error(err);
    }
  };

  useEffect(() => {
    if (tokensInWallet && tokensInWallet.length > 0) {
      setCurrentToken(tokensInWallet[0]);
      fetchData();
    } else {
      setCurrentToken(undefined);
      setTokenData([]);
    }
  }, [tokensInWallet, baseURI]);

  const handleSubmitContract = async (e: any) => {
    const address = e.target.value;
    const ens = await node!.resolveName(address);
    if (!ens) {
      if (!ethers.utils.isAddress(address)) {
        setErr((prevErr) => {
          return { ...prevErr, contract: true };
        });
        return setContract!(undefined);
      }
    }
    setContract!(address);
  };

  const handleSubmitTransfer = async (e: any) => {
    const address = e.target.value;
    const ens = await node!.resolveName(address);
    if (!ens) {
      if (!ethers.utils.isAddress(address)) {
        setErr((prevErr) => {
          return { ...prevErr, transferAddress: true };
        });
        return setTransferAddress("");
      }
    }
    setTransferAddress(address);
  };

  const transferToken = async () => {
    console.log("hello");
    try {
      console.log(account, transferAddress, currentToken);
      console.log(currentToken, "yeet");
      await contractSigner!.transferFrom(
        account,
        transferAddress,
        currentToken
      );
    } catch (err: any) {
      // const message = ethers.utils.ErrorFragment.from(err);
      console.log({ err });
      toast.error(err);
    }
  };

  return (
    <div className="transfer-page-container">
      <div className="user-input">
        <div style={{ marginRight: 5 }}>NFT Contract</div>
        <input
          placeholder="0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D"
          onBlur={handleSubmitContract}
          onChange={() => setErr({ ...err, contract: false })}
          style={{ border: err.contract ? "2px solid red" : "" }}
        ></input>
      </div>
      {baseURI != null && tokensInWallet && tokensInWallet.length > 0 && (
        <>
          <div className="user-owns">
            You own {tokensInWallet.length}{" "}
            {tokensInWallet.length > 1 ? `tokens` : `token`} in this contract!
          </div>
          {tokensInWallet.length > 0 && (
            <>
              <label>
                Select a token to transfer
                <select
                  className="token-list"
                  onChange={(e) =>
                    setCurrentToken(BigNumber.from(e.target.value))
                  }
                >
                  {tokensInWallet.map((value) => {
                    const num = value.toNumber();
                    return (
                      <option value={num} key={num}>
                        {num}
                      </option>
                    );
                  })}
                </select>
              </label>
              {currentToken &&
                tokenData &&
                tokenData.length > 0 &&
                tokenData.findIndex((value) => {
                  return value.tokenNumber === currentToken.toNumber();
                }) >= 0 && (
                  <>
                    <Image
                      size={200}
                      uri={`${
                        tokenData[
                          tokenData.findIndex((value) => {
                            return (
                              value.tokenNumber === currentToken.toNumber()
                            );
                          })
                        ].image
                      }`}
                      provider={node}
                      address="0xf8a8db29A9fBA05aC4E36b8d7Df6D47D120247fD"
                      style={{ borderRadius: "5%" }}
                    />
                  </>
                )}
              <div className="user-input">
                <div style={{ marginRight: 5 }}>Transfer to</div>
                <input
                  placeholder="staledegree.eth"
                  onBlur={handleSubmitTransfer}
                  onChange={() => setErr({ ...err, transferAddress: false })}
                  style={{ border: err.transferAddress ? "2px solid red" : "" }}
                ></input>
              </div>
              {currentToken && account && transferAddress && (
                <button className="tranferButton" onClick={transferToken}>
                  Transfer
                </button>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Transfer;
