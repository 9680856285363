import { useCallback, useContext, useEffect, useState } from "react";
import { EthereumContext } from "../contexts/ethereum";
import Davatar from "@davatar/react";
import Blockie from "./Blockie";
import { toast } from "react-toastify";

function addressConcat(addr: string): string {
  const l = addr.length,
    n = 12;
  return addr.substring(0, n) + "..." + addr.substring(l - n, l);
}

const Account = ({ address }: { address: string | null | undefined }) => {
  const [name, setName] = useState<string | null>(null);
  const { disconnect, node } = useContext(EthereumContext);

  const fetchENS = useCallback(async () => {
    if (address && node) {
      const ens = await node.lookupAddress(address);
      setName(ens);
    }
  }, [address, node]);

  useEffect(() => {
    fetchENS();
  }, [fetchENS]);

  return address ? (
    <div className="row">
      <div className="avatar">
        <Davatar
          address={address.toLowerCase()}
          size={36}
          provider={node}
          defaultComponent={<Blockie address={address} />}
        />
      </div>
      <div className="column address">
        {name ? (
          name
        ) : (
          <p className="address-element">{addressConcat(address)}</p>
        )}
      </div>
      <button
        className="disconnect"
        onClick={() => {
          toast.dark("Disconnected from MetaMask", { autoClose: 1500 });
          if (disconnect) {
            disconnect();
          }
        }}
      >
        Disconnect
      </button>
    </div>
  ) : (
    <></>
  );
};
export default Account;
