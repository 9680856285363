import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Transfer from "./components/TransferPage/Transfer";
import AccountBar from "./components/Account/AccountBar";
function App() {
  return (
    <div className="App">
      <Navbar />
      <AccountBar />
      <Transfer />
      <Footer />
    </div>
  );
}

export default App;
