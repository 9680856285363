import React, { useContext } from "react";
import { EthereumContext } from "../contexts/ethereum";
import Account from "./Account";
import "./account.css";
const AccountBar = () => {
  const { account, connect, error } = useContext(EthereumContext);

  return (
    <div className="account-bar">
      {account ? (
        <Account address={"0x89188728261DDCea66736e9D9A2Cc125A42bb427"} />
      ) : error ? (
        <>{error}</>
      ) : (
        <button onClick={connect}>Connect Wallet</button>
      )}
    </div>
  );
};

export default AccountBar;
