import React, { createContext, useEffect, useMemo, useState } from "react";
import { BigNumber, ethers } from "ethers";
import { targetNetwork, metamaskChain } from "../constants";
import { toast } from "react-toastify";
import contractAbi from "../constants/contractAbi.json";
export interface IEthereumContextValues {
  connect: () => void;
  disconnect: () => void;
  account: string | null | undefined;
  tokensInWallet: BigNumber[];
  baseURI: string | null;
  node: ethers.providers.BaseProvider;
  provider: ethers.providers.Web3Provider;
  metamask: boolean | undefined;
  error: string;
  contract: string | null;
  setContract: React.Dispatch<React.SetStateAction<string | undefined>>;
  contractSigner: ethers.Contract;
}
export const EthereumContext = createContext<Partial<IEthereumContextValues>>(
  {}
);

function addressConcat(addr: string) {
  const l = addr.length,
    n = 15;
  return addr.substring(0, n) + "..." + addr.substring(l - n, l);
}

export const EthereumProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { ethereum } = window;
  const [account, setAccount] = useState<string>();
  const [baseURI, setBaseURI] = useState<string | null>(null);
  const [tokensInWallet, setTokensInWallet] = useState<BigNumber[]>([]);
  const [error, setError] = useState<string>();
  const [contract, setContract] = useState<string>();
  const [chainId, setChainId] = useState<string>();

  const provider = useMemo(() => {
    if (ethereum) {
      return new ethers.providers.Web3Provider(ethereum);
    }
    setError("Install Metamask to Login");
  }, [chainId]);

  const node = useMemo(() => {
    try {
      if (chainId) {
        //Connects to Metamask Chain
        return ethers.getDefaultProvider(parseInt(chainId));
      }
      //defaults Mainnet
      return ethers.getDefaultProvider();
    } catch (err: any) {
      //If Connection to Chain fails use Metamask Provider
      return provider;
    }
  }, [chainId]);

  const connect = async () => {
    try {
      const accounts = await provider!.send("eth_requestAccounts", []);
      setAccount(accounts[0]);
      const chain = await provider!.send("eth_chainId", []);
      setChainId(chain);
    } catch (err: any) {
      toast.error("Error! " + err.message, { autoClose: 15000 });
    }
  };

  const disconnect = () => {
    setAccount(undefined);
  };

  useEffect(() => {
    if (ethereum) {
      ethereum.on("accountsChanged", (accounts: any) => {
        if (accounts) {
          setAccount(accounts[0]);
          console.log("changed acccount", account);
          toast(`changed account: \n ${addressConcat(accounts[0])}`, {
            style: { color: "#7F3B2F" },
          });
        }
      });
      ethereum.on("chainChanged", (chain: any) => {
        console.log("changed", chain);
        setChainId(chain);
        toast(`Chain Changed: ${chain}`);
      });
    }
  }, []);

  const contractNode = useMemo(() => {
    console.log(contract);
    console.log(node);
    if (contract) return new ethers.Contract(contract, contractAbi, node);
    return undefined;
  }, [node, contract]);

  const contractSigner = useMemo(() => {
    console.log("new signer");
    if (contract && provider)
      return new ethers.Contract(contract, contractAbi, provider.getSigner());
  }, [provider, contract, chainId]);

  const getBaseURI = async () => {
    if (contractNode && contract) {
      console.log(contract, contractNode);
      try {
        const URI = await contractNode.baseURI();
        setBaseURI(URI);
        console.log({ URI });
      } catch (err) {
        console.log({ err });
        setBaseURI(null);
        toast.error("Not ERC721: no BaseURI!");
      }
    }
  };

  const updateBalanceOf = async () => {
    if (contractNode && contract) {
      try {
        let balanceOf = 0;
        if (account) {
          balanceOf = (await contractNode.balanceOf(account)).toNumber();
        }
        let tokens: BigNumber[] = [];
        for (let i = 0; i < balanceOf; i++) {
          const token = await contractNode.tokenOfOwnerByIndex(account, i);
          tokens.push(token);
        }
        setTokensInWallet(tokens);
        console.log({ balanceOf }, { tokens });
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getBaseURI();
  }, [contractNode]);

  useEffect(() => {
    updateBalanceOf();
  }, [contractNode, account]);

  return (
    <EthereumContext.Provider
      value={{
        connect,
        disconnect,
        account,
        tokensInWallet,
        baseURI,
        node,
        error,
        provider,
        contract,
        contractSigner,
        setContract,
      }}
    >
      {children}
    </EthereumContext.Provider>
  );
};
