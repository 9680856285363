import React from "react";
import * as blockies from "blockies-ts";
interface IBlockie {
  address: string;
}
const Blockie = (props: IBlockie) => {
  const seed = props.address.toLowerCase() || "";
  const imgUrl = blockies.create({ seed }).toDataURL();
  return (
    <div className="blockie-container">
      <img src={imgUrl} alt={props.address} />
    </div>
  );
};

export default Blockie;
